/* nav styles */
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 0.2rem 0.4rem;
  height: 3.9rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
}

header:not(.home-nav) {
  z-index: 1;
}

header .brand {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
header .brand > div {
  padding: 0.5rem 0;
  display: grid;
}

header .brand h1 {
  font-size: 1.4rem;
  /* font-weight: 600; */
  margin: 0;
  padding: 0.2rem 0 0;
  font-family: "Yellowtail", cursive;
  font-weight: 500;
  font-style: normal;
}

header .brand p {
  font-size: 0.8rem;
  padding: 0 0 0.5rem;
  margin: 0;
  text-align: center;
  color: var(--text-secondary);
  font-family: "Yellowtail", cursive;
  /* font-family: "Birthstone", cursive; */
  line-height: 1rem;
  font-weight: 500;
  font-style: normal;
}

header .brand img {
  height: 2.3rem;
  width: auto;
}

header nav {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 0.95rem;
  font-weight: 500;
}

header nav .nav-items {
  display: flex;
  gap: 1rem;
}
header .nav-items a {
  color: #000;
  /* font-weight: 600; */
}

header nav.dropdown-nav {
  z-index: 1000;
  width: 100%;
  left: 0;
  position: absolute;
  background-color: var(--text-primary);
  transition: 3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  top: 3.9rem;
  padding: 1rem;
  justify-content: center;
  display: none;
}

.nav-btn {
  border-radius: 20px;
  padding: 0.1rem 1.5rem !important;
  font-size: 0.95rem !important;
}

header nav.dropdown-nav .nav-btn {
  display: grid;
  max-width: 15rem;
}

header nav.dropdown-nav .nav-items {
  display: grid;
  max-width: 15rem;
  text-align: center;
}

header nav.dropdown-nav a {
  color: #fff;
}

header .nav-toggle-btn {
  display: none;
  padding: 0;
}

.active-nav-link {
  color: var(--text-secondary) !important;
  position: relative;
}

.active-nav-link::after {
  content: "";
  position: absolute;
  left: 5%;
  bottom: -4px; /* Adjust as necessary to position the underline */
  width: 90%;
  height: 2px;
  background-color: var(--text-secondary);
}

@media (max-width: 730px) {
  header nav.topbar-nav {
    display: none;
  }

  header nav.dropdown-nav {
    display: grid;
  }

  header .nav-toggle-btn {
    display: block;
  }
}

@media (max-width: 380px) {
  header .brand h1 {
    font-size: 1.2rem;
  }
}

/* nav styles */

/* footer styles */
footer .links {
  border-left: solid 2px #fff;
  padding: 0.1rem 2rem 1rem;
  margin: 1.5rem 0;
}

footer .links .quick-links {
  font-size: 1.1rem;
  margin: 0 0 0.5rem;
}

footer .links div {
  display: grid;
  gap: 0.55rem;
  padding: 0 1rem;
  font-size: 0.85rem;
}

footer .footer-top {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  margin-top: 2rem;
}

.footer-top .contact-details {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
}

.footer-top img {
  height: 3.5rem;
  width: auto;
  margin: 1rem;
}

.footer-bottom {
  color: var(--text-primary);
  text-align: center;
  font-weight: 600;
  padding: 0.8rem 1rem;
}
.footer-bottom p {
  margin: 0 1rem;
  font-family: "Birthstone", cursive;
  font-weight: 500;
  font-style: normal;
  font-size: 1.2rem;
}

.footer-bottom a p {
  font-family: "Inter", sans-serif;
  font-size: 0.85rem;
}

@media (max-width: 430px) {
  .footer-top .contact-details {
    /* display: flex; */
    /* flex-wrap: nowrap; */
    /* align-items: center; */
    gap: 0.5rem;
    flex-direction: column-reverse;
  }
}

@media (max-width: 714px) {
  footer .contact-details {
    justify-self: center;
    order: 2;
    border-top: solid 2px #fff;
  }
  footer .links {
    border-left: none;
    text-align: center;
    margin: 1.5rem 0 1rem;
  }
}
/* footer styles end*/

/* hero header */
.page-banner {
  height: 9rem;
  overflow: hidden;
  position: relative;
}

.page-banner .banner-bg {
  /* height: 9rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%; */
  /* height: 100%; */
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.page-banner .banner-content {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.page-banner .banner-header {
  margin: auto;
  background-color: #f0f6ff80;
  position: relative;
  color: var(--text-primary);
  padding: 1rem 4rem;
}

.page-banner .banner-header::after {
  content: "";
  position: absolute;
  left: 10%;
  bottom: 8px; /* Adjust as necessary to position the underline */
  width: 80%;
  height: 3px;
  background-color: var(--text-primary);
  border-radius: 5%;
}

/* hero header end */

/* side scroll container styles */
.side-scroll > * {
  margin: 0.5rem 0;
  width: 100%;
  background-color: #ffffff;
  border-top-left-radius: 0;
  border-radius: 2px;
  padding-bottom: 0.25rem;
}

.side-scroll {
  overflow-x: scroll;
  width: 100%;
  display: inline;
  flex-direction: row;
}

/* side scroll container styles end */

/* slider widget styles */
.slider-section {
  display: flex;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
.slider-container {
  display: grid;
  position: relative;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.slider-container::-webkit-scrollbar {
  display: none;
}
.slide {
  width: 100%;
  position: relative;
}
.slider-footer {
  bottom: 2.3rem;
  position: absolute;
  width: 100%;
}
.slide-title-container {
  display: flex;
  position: relative;
  margin: 0;
  margin-bottom: 0.5rem;
  z-index: 2;
  font-size: 1.2rem;
  color: #ffffff;
}
.slide-title {
  background-color: #0a0a0a9a;
  margin: 0 auto;
  align-self: center;
  padding: 0.2em 0.8em;
  border-radius: 5px;
}
.slide-image,
.slide-video {
  width: 100%;
  filter: sepia(20%) brightness(70%);
  object-fit: cover;
  max-height: 25rem;
  transition: ease-in 2s;
}

.slideshow-buttons {
  z-index: 2;
  position: absolute;
  width: 100%;
  bottom: 1.2rem;
  text-align: center;
}
.slideshow-button {
  margin: 0 4px;
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
  opacity: 0.8;
  -webkit-transition: opacity 0.6s;
  transition: opacity 0.6s ease;
}
.slideshow-button:focus {
  fill: #fafafa70;
}
@media (max-width: 780px) {
  .slider-footer {
    bottom: 2.2rem;
    position: absolute;
    width: 100%;
  }
  .slide-title-container {
    font-size: 1rem;
    margin-bottom: 0;
  }
  .slideshow-buttons {
    bottom: 0.8rem;
  }
  .slideshow-button {
    width: 0.7rem;
    height: 0.7rem;
    margin: 0px 1.8px;
  }
}

@media (max-width: 480px) {
  .slideshow-button {
    width: 0.5rem;
    height: 0.5rem;
  }
}

@media (min-width: 920px) {
  .slider-section {
    /* width: 90%; */
    /* margin: 0rem auto; */
    margin-bottom: 0.5rem;
  }
  .slide-image,
  .slide-video {
    max-height: 30rem;
  }
}
/* slider widget styles end*/

/* loading spinner styles */
.loading-spinner-container {
  position: fixed;
  /* top: 0; */
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.lds-roller {
  /* display: inline-block; */
  position: relative;
  width: 80px;
  height: 80px;
  top: 40%;
  margin: 0 auto;
  transform: translateY(-50%);
}
.contained-loading-spinner .lds-roller {
  top: 0;
  margin: 5px;
  transform: none;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loading spinner styles end */
