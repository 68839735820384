:root {
  --text-primary: #3c0f40;
  --primary: #43114f;
  --secondary: #b2bd39;
  --text-white: #f0f8ff;
  --text-secondary: #b2bd39;
  --text-green: #1b942e;
  --bg-green: #6a7422;
  --goldish: #e9af03;
  --text-goldish: #e9af03;
}

.btn.bg-primary:hover {
  background-color: var(--text-green);
  border-color: var(--text-green);
}

.bg-none {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.btn.bg-none:hover,
.btn.bg-goldish:hover {
  background: var(--text-green);
  border-color: var(--text-green);
}

.btn.bg-purplish {
  /* background-color: transparent; */
  background: linear-gradient(
    to bottom right,
    var(--primary) 0%,
    var(--primary) 25%,
    var(--goldish) 100%
  );
  border-color: var(--goldish);
  color: #fff;
  box-shadow: 0px 3px 10px rgb(233 175 3 / 0.2);
}

.btn.bg-goldish {
  /* background-color: transparent; */
  background: linear-gradient(
    to bottom right,
    var(--goldish) 0%,
    var(--primary) 100%
  );
  border-color: var(--goldish);
  color: #fff;
  box-shadow: 0px 3px 10px rgb(233 175 3 / 0.2);
}

.btn-outline-goldish {
  background-color: transparent;
  border-color: var(--goldish);
  color: var(--text-goldish);
}

.bg-goldish {
  background-color: var(--goldish);
  border-color: var(--goldish);
  color: var(--text-white);
}

.btn.bg-secondary:hover {
  background: var(--text-green);
  border-color: var(--text-green);
  /* background-color: var(--primary);
  border-color: var(--primary); */
}

.padded-page-container {
  padding: 1rem 1rem 0;
}

.segment-container {
  text-align: center;
  margin: 1rem;
}

.segment-header {
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--text-primary);
  text-align: center;
  margin-bottom: 0rem;
}

.segment-title {
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--text-primary);
  text-align: center;
}

.segment-text {
  margin: 0.5rem auto 1rem;
  max-width: 45rem;
}

.btn-link {
  border-radius: 20px;
  padding: 0.1rem 1.5rem !important;
  font-size: 0.95rem !important;
}

.products-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.products-list .product-item {
  display: flex;
  flex-direction: column;
  /* padding: 1rem 0.5rem; */
  /* background-color: #fafafa; */
  background-color: #fff;
  margin: 0.5rem 1rem;
  border-radius: 10px;
  min-width: 12rem;
  max-width: 15rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
}

.products-list .product-item .description-container {
  padding: 1rem 1.2rem;
  text-align: center;
}

.products-list .product-item .description-container p:nth-of-type(1) {
  color: var(--text-primary);
  font-weight: 600;
  margin: 0;
  font-size: 1rem;
}

.products-list .product-item img {
  width: 100%;
  object-fit: cover;
  height: 8rem;
  filter: brightness(1.2) saturate(1.3) contrast(1.1);
}

.product-item.inactive {
  opacity: 0.6;
}

.contacts-container > div,
.contacts-container > a {
  margin: 0.7rem 0;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.contacts-container > div > span,
.contacts-container > a > span {
  margin: 0 0.5rem;
  font-size: 0.8rem;
  line-height: 22px;
  margin-top: -3px;
}

.hero-btn .btn {
  width: 8rem;
}

/* home page */
.hero-section > div {
  background-color: var(--primary);
  /* padding-top: 0.5rem 1rem; */
  max-width: 90rem;
  position: relative;
}

.hero-section .banner-img .slider-section {
  display: unset;
}

.hero-section .banner-img img,
.hero-section .banner-img video {
  height: 38rem;
  max-height: none !important;
  object-position: center center;
  filter: brightness(1.2) contrast(1.1) saturate(1.5);
}

.hero-section .banner-img .slide:nth-of-type(6) img {
  object-position: left center;
}

.hero-section .banner-img .slide:nth-of-type(7) img {
  filter: brightness(1.2) contrast(1.1) saturate(1);
}

.hero-section .banner-img .slide:nth-of-type(5) img {
  filter: brightness(1.2) contrast(1.1) saturate(1.2);
  /* height: 20rem !important; */
}

.hero-section .hero-banner {
  max-width: 39rem;
  margin: auto 1rem;
  padding: 0 2rem;
  z-index: 1;
  text-align: center;
  position: absolute;
  transform: translateX();
  left: 0;
  right: 0;
  bottom: 2rem;
  margin: auto auto 2rem;
}

.hero-section .hero-banner > div {
  min-width: 35rem;
  max-width: 40rem;
}

.hero-section .hero-banner > div {
  background-color: rgba(253, 248, 248, 0.64);
  padding: 1rem;
  /* opacity: 64%; */
  border-radius: 20px;
}

.cta-buttons {
  margin: 1.5rem 0 0.8rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.home-purple {
  /* padding: 2rem 1.5rem; */
  margin: 0;
}

.home-top-cta {
  text-align: center;
  max-width: 45rem;
  margin: 0 auto;
  padding: 2rem 1.5rem;
}

.home-top-cta h2 {
  color: var(--text-goldish);
  padding: 0 2rem;
  margin: 0 auto;
}

.cta-buttons a {
  min-width: 7rem;
}

.home-about .segment-title {
  color: var(--text-goldish);
}

.home-about > div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  gap: 2rem;
  margin: auto;
  margin-top: 1.5remrem;
  /* padding: 2rem 1.5rem 2rem; */
  align-items: center;
  text-align: center;
  max-width: 70rem;
}

.home-about-text {
  padding: 2rem 1.5rem;
}

.home-about img,
.home-about video {
  width: 100%;
  border-radius: 20px;
  height: 21rem;
  filter: brightness(1.2) contrast(1.1) saturate(1.4);
}

.home-about .btn {
  padding: 0.3rem 1.5rem;
  margin-top: 1.3rem;
}

.home-products {
  text-align: center;
  margin: 1rem;
}

.home-products .product-item {
  margin-top: 4.8rem;
}
.home-products .product-item img {
  border-radius: 50%;
  width: 80%;
  align-self: center;
  margin-top: -4rem;
  filter: brightness(1.2) saturate(1.3) contrast(1.1);
}

.home-products .btn {
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 1rem;
}

.certifications {
  background-color: #ffffff7d;
  border-radius: 20px;
  padding: 1rem 2rem 1rem;
  margin: auto;
  margin-top: 2rem;
  max-width: 60rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
}
.certifications p.pending-certifications {
  color: var(--text-green);
}

.certifications-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.certifications-container > div {
  display: flex;
  flex-direction: column;
  /* padding: 1rem 0.5rem; */
  background-color: #fafafa;
  margin: 1rem 1.5rem;
  border-radius: 10px;
  width: 8rem;
}

.certifications-container > div img {
  width: 100%;
  height: auto;
}

.cta-container {
  /* background-color: var(--primary);
  color: var(--text-white); */
}

.cta-container > div {
  margin: 2rem auto;
  max-width: 35rem;
  text-align: center;
  /* background-color: #b2bd39b8; */
  /* background-color: var(--text-white); */
  /* background-color: #7475c13d; */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'%3E%3Cpath fill='%23bfc0b2' fill-opacity='0.4' d='M0 17.83V0h17.83a3 3 0 0 1-5.66 2H5.9A5 5 0 0 1 2 5.9v6.27a3 3 0 0 1-2 5.66zm0 18.34a3 3 0 0 1 2 5.66v6.27A5 5 0 0 1 5.9 52h6.27a3 3 0 0 1 5.66 0H0V36.17zM36.17 52a3 3 0 0 1 5.66 0h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 0 1 0-5.66V52H36.17zM0 31.93v-9.78a5 5 0 0 1 3.8.72l4.43-4.43a3 3 0 1 1 1.42 1.41L5.2 24.28a5 5 0 0 1 0 5.52l4.44 4.43a3 3 0 1 1-1.42 1.42L3.8 31.2a5 5 0 0 1-3.8.72zm52-14.1a3 3 0 0 1 0-5.66V5.9A5 5 0 0 1 48.1 2h-6.27a3 3 0 0 1-5.66-2H52v17.83zm0 14.1a4.97 4.97 0 0 1-1.72-.72l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1 0-5.52l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43c.53-.35 1.12-.6 1.72-.72v9.78zM22.15 0h9.78a5 5 0 0 1-.72 3.8l4.44 4.43a3 3 0 1 1-1.42 1.42L29.8 5.2a5 5 0 0 1-5.52 0l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1-.72-3.8zm0 52c.13-.6.37-1.19.72-1.72l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43a5 5 0 0 1 5.52 0l4.43-4.43a3 3 0 1 1 1.42 1.41l-4.44 4.43c.36.53.6 1.12.72 1.72h-9.78zm9.75-24a5 5 0 0 1-3.9 3.9v6.27a3 3 0 1 1-2 0V31.9a5 5 0 0 1-3.9-3.9h-6.27a3 3 0 1 1 0-2h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 1 1 2 0v6.27a5 5 0 0 1 3.9 3.9h6.27a3 3 0 1 1 0 2H31.9z'%3E%3C/path%3E%3C/svg%3E"); */
  /* background-position: center 20%; */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
  padding: 1rem 1.5rem;
  border-radius: 20px;
  font-weight: 500;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  /* color: var(--text-primary); */
  background-color: var(--primary);
  background: radial-gradient(circle, #3c0f40ce 0%, var(--primary) 100%);
  color: var(--text-white);
}

.cta-container > div > p {
  margin-bottom: 2rem;
}

@media (min-width: 400px) {
  .home-about > div {
    padding: 2rem 1.5rem 2rem;
  }
}
@media (max-width: 730px) {
  .hero-section > div {
    display: grid;
  }

  .hero-section .banner-img img,
  .hero-section .banner-img video {
    margin: 0;
    width: 100%;
    height: 25rem;
  }

  .hero-section .hero-banner {
    margin-bottom: 1.2rem;
  }

  .hero-section .hero-banner > div {
    margin: 0;
    min-width: auto;
  }
}

@media (min-width: 1000px) {
  .hero-section .banner-img img,
  .hero-section .banner-img video {
    /* height: 35rem; */
  }
}

@media (min-width: 1200px) {
  .hero-section .banner-img img,
  .hero-section .banner-img video {
    /* height: 35rem; */
  }
}

@media (min-width: 1450px) {
  .hero-section > div {
    margin: 1rem auto;
    border-radius: 20px;
    background-color: #fff;
  }

  .hero-section .banner-img img,
  .hero-section .banner-img video {
    border-radius: 20px;
    /* border-bottom-left-radius: 40%;
    border-top-left-radius: 0; */
  }
}
/* home page end */

/* products page */
.products-page-segment.segment-container {
  margin-top: 2rem;
}

.product-page.product-item {
  margin-top: 1rem;
}

.product-page.product-item img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  width: 100%;
  align-self: center;
}
/* products page */

/* product page */
.breadcrumb {
  font-weight: 600;
  font-style: italic;
  display: flex;
  align-items: center;
  vertical-align: middle;
  margin-top: 1rem;
}

.product-hero {
  background-color: #fff;
  padding: 0.5rem 1rem;
}

.product-hero .product-details {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr)); */
  padding: 1rem;
  gap: 1rem;
  max-width: 70rem;
  margin: 0 auto;
}

.product-hero .product-details h2 {
  font-size: 1.1rem;
  text-align: center;
  color: var(--text-green);
}
.product-hero .product-details .product-description {
  padding: 1rem 1.5rem;
  margin: auto 0;
}

.product-hero .product-details .main-images {
  /* 
  flex-direction: row-reverse; */
  gap: 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.product-hero .product-details .main-images > div {
  width: fit-content;
}

.product-hero .product-details .hero-btn {
  text-align: center;
  margin: 1.5rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
}
.product-hero .product-details .main-images img {
  border-radius: 10px;
  /* width: 100%;
  height: auto; */
  height: 17rem;
  width: auto;
  object-fit: cover;
  filter: brightness(1.2) saturate(1.3) contrast(1.1);
}

/* .product-hero .product-details .main-images div {
  padding: 1rem;
}
.product-hero .product-details .main-images div:nth-child(1) {
  flex: 4;
}
.product-hero .product-details .main-images div:nth-child(2) {
  flex: 3;
}
.product-hero .product-details .main-images div:nth-child(1) img {
  max-height: 18rem;
}

.product-hero .product-details .main-images div:nth-child(2) img {
  margin-top: 13rem;
  max-height: 12rem;
} */

@media (max-width: 450px) {
  .product-hero .product-details .main-images img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 922px) {
  .product-hero .product-details .main-images {
    /* display: flex;
    gap: 2rem;
    flex-direction: row;
    justify-content: center; */
  }

  /* .product-hero .product-details .main-images div:nth-child(2) img {
    margin-top: 7rem;
    max-height: 13rem;
  }
  .product-hero .product-details .main-images div:nth-child(1) img {
    max-height: 15rem;
  }
  .product-hero .product-details .main-images div:nth-child(1) {
    flex: 3;
  }
  .product-hero .product-details .main-images div:nth-child(2) {
    flex: 3;
  } */
}
/* product page end */

/* image slider  */
.horizontal-image-list-container {
  position: relative;
}

.horizontal-image-list {
  position: relative;
  grid-template-columns: 20px 1fr 20px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.horizontal-image-list-body {
  /* --image-list-percentage: 32%; */
  display: flex;
  /* grid-gap: 10px; */
  /* grid-auto-flow: column; */
  /* grid-auto-columns: calc(var(--image-list-percentage) - 40px); */
  /* grid-template-rows: minmax(150px, 1fr); */
  justify-content: center;
  padding: 0 2rem;
}

.horizontal-image-list-body > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.5rem;
  height: 18rem;
  width: fit-content;
  box-shadow: 5px 5px 8px #ecebeb;
  position: relative;
}

.horizontal-image-list-body > div img {
  object-fit: cover;
  height: 100%;
  border-radius: 5px;
}
.images-slideshow-container {
  padding: 1rem;
  filter: brightness(1.2) saturate(1.3) contrast(1.1);
}
/* image slider  */

/* about us */
.segment-container.about-us {
  padding: 2rem 2rem 1rem !important;
  max-width: 60rem;
  margin: 0 auto;
  color: var(--text-primary);
}

.core-values {
  padding: 1rem 2rem;
  max-width: 60rem;
  margin: 0 auto;
}

.core-values .segment-header {
  padding-bottom: 1rem;
  width: fit-content;
  margin: 0 auto;
  position: relative;
  color: var(--text-green);
}
.core-values h2.segment-header::after {
  content: "";
  position: absolute;
  margin: 0 auto;
  left: 5%;
  bottom: 8px;
  width: 90%;
  height: 3px;
  background-color: var(--text-green);
  border-radius: 5%;
}

.core-values > div {
  margin-top: 1.3rem;
}

.core-values > div {
  display: flex;
  text-align: center;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.core-values .commitment-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.5rem;
  margin: 0.8rem 0;
  color: var(--text-white);
  background: linear-gradient(
    to bottom right,
    var(--secondary) 0%,
    var(--text-green) 100%
  );
  padding: 1rem 2rem;
  border-radius: 20px;
  filter: contrast(1.3) saturate(1.2);
  min-width: 15rem;
  max-width: 24rem;
}

.core-values .commitment-item > div {
  position: relative;
  padding-bottom: 0.5rem;
  margin: 0 auto;
}

.core-values .commitment-item > div > span {
  color: var(--text-primary);
  font-size: 1.1rem;
}

.core-values .commitment-item > span {
  padding: 0.5rem;
}

.core-values .commitment-item > div span::after {
  content: "";
  position: absolute;
  margin: 0 auto;
  left: 10%;
  bottom: 0px;
  width: 80%;
  height: 2.5px;
  background-color: var(--text-goldish);
  border-radius: 5%;
}
/* about us end */

/* contact page */
.contact-page-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  padding: 2rem 1.5rem;
  gap: 1rem;
  justify-content: center;
  color: var(--text-primary);
  max-width: 70rem;
  margin: 0 auto;
}

.contact-page-info .contacts-container {
  justify-self: center;
  margin-top: 5rem;
  display: grid;
  height: fit-content;
  margin-bottom: 2rem;
}

.contact-page-info .contacts-container > a > span {
  font-size: 1rem;
}

.contact-page-info .contacts-container .contact-icon {
  color: var(--text-primary);
  background-color: #d9d9d9;
  padding: 0.5rem;
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  margin-right: 0.8rem;
}

.contact-page-info .contact-form {
  background-color: #fff;
  padding: 1rem 1.5rem;
  border-radius: 20px;
  max-width: 25rem;
  width: unset;
  margin: 0 auto;
}

.contact-page-info .contact-form .form-title {
  text-align: center;
  font-weight: 600;
  font-style: italic;
  font-size: 1.2rem;
  margin: 0.5rem 0 1rem;
}

.contact-page-map {
  margin: 0 auto;
  max-width: 70rem;
}

.contact-page-map > div {
  background-color: #fff;
  margin: 1rem 1.5rem;
  padding: 1rem;
  border-radius: 20px;
}

.contact-page-map h2 {
  text-align: center;
  margin: 0;
  font-size: 1.2rem;
}

@media (max-width: 714px) {
  .contact-page-info .contacts-container {
    margin-top: 2rem;
    order: 2;
  }
}
/* contact page end*/
