/* **** GENERIC STYLES APPLYING IN BOTH DARK AND LIGHT MODE ACCROSS MULTIPLE COMPONENTS **** */
/* css cariables */
:root {
  --primary: #0077b6;
  /* --text-color: #595959; */
  --text-color: #000;
  --text-muted: #a9a9a9;
  --bg-grey: #ecf0f3;
  --shaded-white: #fafafa;
  /* --bg-grey: #f6f6f6; */
  /* --bg-grey:#fdf9f0b8; */
  /* --secondary: #6c757d; */
  --secondary: #75757a;
  --bright-red: #ff0505;
  /* --secondary: #aaaaaa; */
  --mild-gray: #eee;
  --green: green;
  --info: #17a2b8;
  --bg-dark-gray: #313a46;
  --success: #0abe78;
  --danger: #ff5b5b;
  --warning: #ffcf29;
  --orange: #fe753e;
  --orange-fade: #fe753e;
  --yellow: #ffc144;
  --navy-blue: #023047;
  --cool-dark: #0e1a25;
  --gold: #f4b459;
  --nav-background: #dcdcde;
}

* {
  box-sizing: border-box !important;
  scrollbar-width: thin;
  scrollbar-color: var(--text-muted) var(--bg-grey);
}

body {
  font-family: "Inter", sans-serif;
  /* background-color: var(--bg-grey); */
  background-color: #fff;
  margin: 0 !important;
  font-size: 0.92rem;
  color: var(--text-color);
}
a {
  text-decoration: none;
  color: inherit;
}
textarea {
  font-family: inherit;
  font-size: 0.9rem;
  resize: none;
}
svg {
  vertical-align: middle;
  margin-top: -0.2rem;
}
button {
  cursor: pointer;
}
.app {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.app-content {
  margin-bottom: auto;
}
#app-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #00000080;
}
.card {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  /* background-color: #fff; */
  background-clip: border-box;
  border: 0.5px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-sizing: border-box;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}
.component-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  position: relative;
}
.component-title {
  font-size: 1.5rem;
  color: var(--secondary);
  font-weight: 600;
  text-transform: capitalize;
  margin: 1rem 1rem 0;
}
.section-container {
  background-color: #fff;
  margin: 0.5rem 0.5rem 1rem;
  padding: 0.5rem;
  border-radius: 10px;
}

.section-header {
  text-transform: capitalize;
}
.inner-section-title {
  color: var(--secondary);
  font-weight: 600;
  text-transform: uppercase;
  margin: 0.5rem 0;
  font-size: 0.95rem;
}
.dot-separator {
  margin: 0 0.3rem;
}
.dot-separator::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1rem' width='1rem'%3E%3Ccircle cx='8' cy='12' r='.12rem' stroke='currentColor' fill='currentColor' /%3E%3C/svg%3E");
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.3rem 1.2rem;
  margin: 0 1rem;
  font-size: 0.9rem;
  line-height: 1.3;
  border-radius: 0.4rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  filter: saturate(1.3) contrast(1.2);
}
.radial-btn {
  border-radius: 20px;
}
.btn-outline-dark {
  color: #0e1a25;
  border-color: #0e1a25;
}

.form-row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  gap: 1rem;
  /* margin-right: -5px;
  margin-left: -5px; */
}
.form-row > * {
  width: max-content;
}
.form-group {
  margin-top: 1rem;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.form-control {
  display: block;
  width: 95%;
  /* width: 100%; */
  /* height: calc(1.5em + .75rem + 2px); */
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  margin-top: 0.3rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 0.2rem;
  margin-top: 0.5rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-footer {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}
.bg-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.bg-primary {
  color: var(--text-white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.bg-secondary {
  color: var(--text-white);
  /* background-color: var(--secondary); */
  background: linear-gradient(
    to bottom right,
    var(--secondary) 0%,
    var(--text-green) 100%
  );
  border-color: var(--secondary);
  box-shadow: 0px 3px 10px rga(178 189 57 / 0.3);
  filter: saturate(1.3) contrast(1.2);
}

.bg-gray {
  color: #222222e6;
  background-color: #cacdcf;
  border-color: #cacdcf;
}

.text-muted {
  color: #a7a5a5;
}
.text-bold {
  font-weight: 600;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-secondary {
  color: var(--text-secondary);
}
.text-primary {
  color: var(--text-primary);
}
.badge {
  padding: 0.2rem;
  border-radius: 4px;
}
.success-badge {
  color: var(--success);
  background-color: rgba(10, 190, 120, 0.3);
}
.danger-badge {
  color: var(--danger);
  background-color: rgba(255, 91, 91, 0.3);
}
.secondary-badge {
  color: var(--secondary);
  background-color: rgba(108, 117, 125, 0.3);
}

.hide {
  display: none;
}
.fade {
  background-color: #00000080;
  transition: opacity 0.15s linear;
}
.separator {
  color: var(--text-muted);
  margin: 1rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #a7a5a5;
}

.separator:not(:empty)::before,
.separator:not(:empty)::after {
  margin: 0 0.25em;
}

/* Global slider styles */
.left-slider-button,
.right-slider-button {
  display: inline-block;
  z-index: 1;
  width: 2rem;
  height: 2rem;
  text-align: center;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-1rem);
  position: absolute;
  cursor: pointer;
  background-color: #00000080;
  color: #fff;
  line-height: 2rem;
  border: none;
  outline: none;
  font-size: 1.2rem;
}

.left-scroller-button,
.right-scroller-button {
  display: inline-block;
  z-index: 1;
  width: 1.8rem;
  height: 1.8rem;
  max-width: 1.8rem;
  text-align: center;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-1.3rem);
  position: absolute;
  cursor: pointer;
  background-color: #00000090;
  color: #fff;
  line-height: 1.2rem;
  /* vertical-align: ; */
  border: none;
  outline: none;
  font-size: 1.1rem;
  padding-top: 2px;
}
.left-scroller-button {
  left: 0.15rem;
}
.right-scroller-button {
  right: 0.25rem;
}
.right-slider-button::before,
.right-scroller-button::before {
  align-items: baseline;
  content: "\276F";
  margin-right: -2px;
}
.left-slider-button::after,
.left-scroller-button::after {
  content: "\276E";
  margin-top: 3px;
  margin-left: -3px;
}
.left-slider-button {
  margin-left: 1rem;
}
.right-slider-button {
  right: 1rem;
}
/* end global slider buttons */
